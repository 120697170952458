import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";

export default function SearchModal({ eventId, role, open, onClose }) {
  const { control, handleSubmit, watch } = useForm();

  const [isEmailEnabled, setIsEmailEnabled] = useState(false);
  const [isMobileNumberEnabled, setIsMobileNumberEnabled] = useState(false);
  const [searchId, setSearchId] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const emailValue = watch("email");
  const mobileNumberValue = watch("mobileNumber");

  const [searchBadge, { loading, error }] = useMutation(gql`
    mutation SearchBadge(
      $eventId: String!
      $role: String!
      $searchData: String!
    ) {
      searchBadge(eventId: $eventId, role: $role, searchData: $searchData)
    }
  `);

  const handleSearch = async (variables) => {
    const searchData = variables.email || variables.mobileNumber;
    try {
      const response = await searchBadge({
        variables: {
          searchData,
          eventId,
          role,
        },
      });
      const { searchBadge: searchResult } = response.data;
      setSearchId(searchResult);
      setShowButton(!!searchResult);
    } catch (error) {
      console.error("Failed to search for badge.", error);
      setShowButton(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    setIsEmailEnabled(mobileNumberValue === "");
    setIsMobileNumberEnabled(emailValue === "");
  }, [emailValue, mobileNumberValue]);

  useEffect(() => {
    setIsEmailEnabled(true);
    setIsMobileNumberEnabled(true);
  }, [open]);

  const downloadUrl = `https://api-reg.eventegration.com/graphql/badge/${eventId}/${role}/${searchId}`;

  const handleDownload = () => {
    window.open(downloadUrl);
    setShowButton(null);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      sx={{
        background: "rgba(0, 0, 0, 0.6)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: isSmallScreen ? "80%" : 400,
          height: "auto",
          position: "relative",
          bgcolor: "white",
          outline: "none",
          p: 4,
          borderRadius: 7,
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 20, right: 20 }}
        >
          <CloseIcon />
        </IconButton>
        {!showButton ? (
          <>
            <Typography variant="h5" textAlign="center" gutterBottom>
              Search your Badge
            </Typography>
            <Typography variant="subtitle1" textAlign="center" gutterBottom>
              Enter your registered email id or mobile number to search your
              badge.
            </Typography>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        type="email"
                        fullWidth
                        required
                        disabled={!isEmailEnabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider>
                    <Chip label="OR" />
                  </Divider>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="mobileNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Mobile Number"
                        type="number"
                        fullWidth
                        required
                        disabled={!isMobileNumberEnabled}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" fullWidth>
                    Search &nbsp;{" "}
                    {loading && <CircularProgress color="warning" />}
                  </Button>
                </Grid>
                {error && (
                  <Stack
                    direction="row"
                    justifyContent={"center"}
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      textAlign={"center"}
                      color="error"
                      sx={{ mt: 2 }}
                    >
                      {error.message}
                    </Typography>
                  </Stack>
                )}
              </Grid>
            </form>
          </>
        ) : (
          <>
            <Stack direction={"column"} justifyContent={"center"} spacing={3}>
              <Typography variant="h5" textAlign="center" gutterBottom>
                Download your Badge
              </Typography>
              <Button
                onClick={handleDownload}
                variant="contained"
                color="success"
                fullWidth
              >
                Download
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
}
