import React, { useState } from "react";
import PropTypes from "prop-types";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CircularProgress, Stack, Typography } from "@mui/material";

const PaypalBtns = ({ amount, onPaymentSuccess, currency, buyerEmail, clientId, description,base }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: description,
          amount: {
            currency_code: currency,
            value: amount,
          },
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
      payer: {
        email_address: buyerEmail,
      },
    });
  };

  // const onApprove = (data, actions) => {
    
  //   setLoading(true);
  //   actions.order.capture().then(() => {
  //     console.log(data,"data", actions);
  //     onPaymentSuccess(actions);
  //     setLoading(false);
  //   });
  // };

  const onApprove = (data, actions) => {
    setLoading(true);
    actions.order.capture().then((details) => {
      // console.log(data, details, "data and details");
      onPaymentSuccess(details, actions);
      setLoading(false);
    });
  };
  

  const onError = (data, actions) => {
    console.log(data, actions,"Error");
    setErrorMessage("An error occurred with your payment. Please try again.");
    // Additional error handling can be added here
  };

  return (
    <>
    <Stack direction={"row"} spacing={2} py={2} width={"100%"} justifyContent={"center"}  alignItems="center" sx={{backgroundColor:"wheat"}}>
      {loading && <CircularProgress />}
      <PayPalScriptProvider
        options={{
          "client-id": clientId,
          currency:currency,

        }}
      >
        <PayPalButtons
          style={{ layout: "vertical" }}
          amount={amount}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        /> 
      </PayPalScriptProvider>
      {errorMessage && <Typography sx={{ color: "red" }}>{errorMessage}</Typography>}
      </Stack>
    </>
  );
};

export default PaypalBtns;


// import { PayPalScriptProvider } from "@paypal/react-paypal-js";
// import { PaymentForm } from "./PaymentForm";
// import { useState, useEffect } from "react";
// import PropTypes from "prop-types";

// const PaypalBtns = ({ amount, onPaymentSuccess, currency, buyerEmail, clientId, description, base }) => {
//   const [clientToken, setClientToken] = useState(clientId);
//   // const base = "https://api-m.sandbox.paypal.com";
//   console.log(base, "base");

//   const initialOptions = {
//     "client-id": clientId,
//     "enable-funding": "",
//     "disable-funding": "paylater,venmo",
//     "data-sdk-integration-source": "integrationbuilder_ac",
//     "data-client-token": clientId,
//     components: "hosted-fields,buttons",
//   };

//   // useEffect(() => {
//   //   (async () => {
//   //     const response = await fetch("/api/token", {
//   //       method: "POST",
//   //     });
//   //     const { client_token } = await response.json();
//   //     setClientToken(client_token);
//   //   })();
//   // }, []);
//   return (
//     <>
//       {clientId ? (
//         <PayPalScriptProvider options={initialOptions}>
//           <PaymentForm amount={amount} onPaymentSuccess={onPaymentSuccess}
//             currency={currency} buyerEmail={buyerEmail} clientId={clientId}
//             description={description} base={base} />
//         </PayPalScriptProvider>
//       ) : (
//         <h4>WAITING ON CLIENT TOKEN</h4>
//       )}
//     </>
//   );
// };


// PaypalBtns.propTypes = {
//   amount: PropTypes.number.isRequired,
//   onPaymentSuccess: PropTypes.func.isRequired,
//   currency: PropTypes.string.isRequired,
//   buyerEmail: PropTypes.string.isRequired,
//   clientId: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
//   base: PropTypes.string.isRequired,

// };
// export default PaypalBtns;
