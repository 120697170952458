import { gql, useQuery } from '@apollo/client';
import { useFeatures } from './GetFeatures';

export function usePaymentDetail(eventId, role) {
  const { data: paymentData, loading: paymentLoading } = useQuery(GET_PAYMENT_DETAIL, {
    variables: { eventId, role },
  });
  
  const { featureData, featureLoading } = useFeatures(eventId, role);

  let featuresList = [];
  if (featureData && featureData.getFeatureList) {
    featuresList = JSON.parse(featureData.getFeatureList.features);
  }

  const isLoading = paymentLoading || featureLoading;

  // Adjust the condition based on your requirements
  const hasPaymentFeature = featuresList.includes("PaymentEnable");

  const data = hasPaymentFeature ? (paymentData ? paymentData.getPaymentList : null) : null;

  return {
    paymentData: data,
    isLoading,
  };
}

export const GET_PAYMENT_DETAIL = gql`
  query ($eventId: String!, $role: ROLE!) {
    getPaymentList(eventId: $eventId, role: $role) {
      paymentMethod
      secretKey
      publicKey
      apiKey
      apiurl
      currency
    }
  }
`;
