import React from "react";
import Footer from "./Components/Footer";
import NavBar from "./Components/NavBar";

export default function ContactPage() {
  return (
    <div>
      <NavBar />
      <div style={{ height: 80 }} />
      <Footer />
    </div>
  );
}
