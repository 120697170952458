import React from "react";
import { Typography, Box, Button, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import noUserFoundImg from "../Assets/nouser.jpeg";

const NoUserFound = ({ iframe, link }) => {
    console.log(iframe, link);
    const navigate = useNavigate();
    const handleNavigate = () => {
        if (iframe) {
            navigate(`/iframe/${link}`);
        } else {
            navigate(`/${link}`);
        }
    };
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            bgcolor="#f0f2f5"
            p={4}
            m={0}
        >
            <motion.img
                src={noUserFoundImg}
                alt="Link Not Found"
                style={{ width: "70%", maxWidth: "350px", marginBottom: "2.5rem", borderRadius: "20px" }} // Added maxWidth and changed width for responsiveness
                initial={{ opacity: 0, scale: 0.5, rotate: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                whileHover={{ rotate: 0 }} // Tilt motion effect on hover
                transition={{ duration: 0.5 }}
            />

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
            >
                <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                    Oops! It seems you're not registered.
                </Typography>
                <Typography variant="body1" align="center" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                    The registration you are trying to access does not exist. Please start a new registration.
                </Typography>
                <Stack direction={"row"} spacing={2} justifyContent={"center"}>

                <Button variant="contained" color="secondary" sx={{py:2}} alignItems="center" onClick={handleNavigate}>
                    Register Now
                </Button>
                </Stack>
            </motion.div>
        </Box>
    );
};

export default NoUserFound;
