import { Box, Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { PreviewAgenda3 } from "./PreviewAgenda3";
import { PreviewAgenda2 } from "./PreviewAgenda2";
import PreviewAgenda from "./PreviewAgenda";

export default function PreviewSection({ selectedPreview, agendaDetails }) {
  const [selectedDay, setSelectedDay] = useState(0);
  console.log("selected preview from backend", selectedPreview);
  console.log("selected daya from backend", selectedDay);
  const handleDayChange = (event, newValue) => {
    setSelectedDay(newValue);
  };

  console.log("data in preview section in website", agendaDetails);

  //   const sortedAgendaDetails = agendaDetails?.map((dayData) => {
  //     // Check if dayData is an array before sorting
  //     console.log('dayDtatttt',dayData);
  //     if (Array.isArray(dayData)) {
  //       return dayData.slice().sort((a, b) => {
  //         const startTimeA = new Date(`1970-01-01T${a.startTime}`);
  //         const startTimeB = new Date(`1970-01-01T${b.startTime}`);
  //         return startTimeA - startTimeB;
  //       });
  //     } else {
  //       // If dayData is not an array, return an empty array
  //       return [];
  //     }
  //   });

  const sortedAgendaDetails = agendaDetails?.map((dayData) =>
    dayData.slice().sort((a, b) => {
      const startTimeA = new Date(`1970-01-01T${a.startTime}`);
      const startTimeB = new Date(`1970-01-01T${b.startTime}`);
      return startTimeA - startTimeB;
    })
  );

  console.log(
    "data in preview section in website after sorting",
    sortedAgendaDetails
  );

  return (
    <>
      <Box p={3}>
        <Grid
          margin={2}
          padding={2}
          container
          justifyContent="center"
          marginBottom={0}
          sx={{ margin: -1 }}
        >
          <Tabs
            value={selectedDay}
            onChange={handleDayChange}
            textColor="inherit"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {agendaDetails?.map((dayData, index) => (
              <Tab key={index} label={`Day ${index + 1}`} />
            ))}
          </Tabs>
        </Grid>
        {/* Conditionally render the selected preview component */}
        {selectedPreview === "default" && (
          <PreviewAgenda3
            agendaDetails={sortedAgendaDetails}
            selectedDay={selectedDay}
          />
        )}
        {selectedPreview === "tabular" && (
          <PreviewAgenda2
            agendaDetails={sortedAgendaDetails}
            selectedDay={selectedDay}
          />
        )}
        {selectedPreview === "classic" && (
          <PreviewAgenda
            agendaDetails={sortedAgendaDetails}
            selectedDay={selectedDay}
          />
        )}
      </Box>
    </>
  );
}
