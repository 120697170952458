import React from "react";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import linkNotFoundImg from "../Assets/linknotfound.jpeg";

const LinkNotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="#f0f2f5"
      p={4}
      m={0}
    >
      <motion.img
        src={linkNotFoundImg}
        alt="Link Not Found"
        style={{ width: "80%", maxWidth: "400px", marginBottom: "2.5rem", borderRadius: "20px" }} // Added maxWidth and changed width for responsiveness
        initial={{ opacity: 0, scale: 0.5, rotate: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        whileHover={{ rotate: 0 }} // Tilt motion effect on hover
        transition={{ duration: 0.5 }}
      />

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}> {/* Adjusted font size for responsiveness */}
          Oops! It seems you've followed a broken link.
        </Typography>
        <Typography variant="body1" align="center" gutterBottom sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }}> {/* Adjusted font size for responsiveness */}
          The link you are trying to access does not exist. Please check the URL and try again.
        </Typography>
      </motion.div>
    </Box>
  );
};

export default LinkNotFound;
