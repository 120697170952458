import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import eventClosedImg from "../Assets/eventclose.jpeg";

const EventClosed = () => {


    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            bgcolor="#f0f2f5"
            p={4}
            m={0}
        >
            <motion.img
                src={eventClosedImg}
                alt="Event Closed"
                style={{ width: "80%", maxWidth: "400px", marginBottom: "2.5rem", borderRadius: "20px" }}
                initial={{ opacity: 0, scale: 0.5, rotate: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                whileHover={{ rotate: 0 }}
                transition={{ duration: 0.5 }}
            />

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.5 }}
            >
                <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                    Sorry, this event is closed.
                </Typography>
                <Typography variant="body1" align="center" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                    The event you are trying to register for has already closed..
                </Typography>
            </motion.div>
        </Box>
    );
};

export default EventClosed;
