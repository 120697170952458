import React from "react";
import Box from "@mui/material/Box";
// import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
// import mainLogo from "../Assets/eventegration.png";

import footerimg from "../Assets/newImage/decoration-circles.png";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import PhoneIphoneTwoToneIcon from "@mui/icons-material/PhoneIphoneTwoTone";
// import Typography from '@mui/material/Typography';
// import { CardActionArea } from "@mui/material";
// import footerImage from "../Assets/footer.png";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";
// import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
// import CardActions from "@mui/material/CardActions";
// import Paper from "@mui/material/Paper";
// import Stack from '@mui/material/Stack';
// import ServicesCard from "../Components/ServicesCard";

// import NavBar from '../Components/NavBar';
export default function Footer() {
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        sx={{
          backgroundImage: `url(${footerimg})`,

          backgroundRepeat: "no-repeat",
          backgroundSize: " 100px 150px",
          backgroundPosition: "left 0px top 50px",
        }}
      >
        <Grid item xs={12} md={12} lg={12} py={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItem: "center",
              textAlign: "center",
              paddingTop: 5,
            }}
          >
            <Typography
              variant="h4"
              py={4}
              fontSize={32}
              fontWeight={600}
              color="#223150"
            >
              CONNECT WITH US
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItem: "center",
            }}
          >
            <Typography
              variant="h4"
              py={2}
              fontSize={24}
              fontWeight={600}
              color="#223150"
            >
              <PhoneIphoneTwoToneIcon /> +968 9522 9600 &nbsp; | &nbsp;
            </Typography>

            <Typography
              variant="h4"
              py={2}
              fontSize={24}
              fontWeight={600}
              color="#223150"
            >
              <PhoneIphoneTwoToneIcon /> +971 50 926 9522 &nbsp; | &nbsp;
            </Typography>
            <Typography
              variant="h4"
              py={2}
              fontSize={24}
              fontWeight={600}
              color="#223150"
            >
              <PhoneIphoneTwoToneIcon /> +91 9716 209 269
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "center", paddingBottom: 5 }}
          >
            <Stack direction="row" spacing={1}>
              <IconButton aria-label="LinkedIn" sx={{ color: "#00509D" }}>
                <LinkedIn />
              </IconButton>
              <IconButton aria-label="Facebook" sx={{ color: "#00509D" }}>
                <Facebook />
              </IconButton>
              <IconButton sx={{ color: "#00509D" }} aria-label="Twitter">
                <Twitter />
              </IconButton>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12} py={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItem: "center",
              textAlign: "center",
              paddingTop: 1,
              borderTop: "1px solid grey",
            }}
          >
            Copyright © 2021 - 2022 eventegration, All Rights Reserved.
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
