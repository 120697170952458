import * as React from "react";
// import PropTypes from 'prop-types';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// import { AiOutlineMenu } from "react-icons/ai";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import mainLogo from "../Assets/newImage/logo.svg";
import { MenuTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;
const navItems = [
  { name: "Home", path: "/" },
  { name: "Contact", path: "/contact" },
];
// const navPath = ['/home', '/contact'];

function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img src={mainLogo} alt="" width="80%" height="auto" />
      </Typography>
      <Divider />
      <List>
        {navItems.map((index, item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              onClick={() => navigate(index.path)}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={index.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <Button
          variant="contained"
          sx={{ backgroundColor: "#00509D", marginTop: 2 }}
          disableElevation
          onClick={() => navigate(navItems[1].path)}
        >
          Contact Sales
        </Button>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <AppBar component="nav" sx={{ backgroundColor: "#fff" }}>
        <Toolbar>
          <img
            src={mainLogo}
            alt=""
            width="250px"
            height="80px"
            sx={{
              objectFit: "contain",
            }}
          />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: "none" },
              color: "black",
              position: "absolute",
              left: "auto",
              right: "0",
              top: "25%",
            }}
          >
            <MenuTwoTone />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            {/* <img
              src={mainLogo}
              alt=""
              width="250px"
              height="80px"
              sx={{ objectFit: "contain" }}
            /> */}
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((index, item) => (
              <Button
                key={item}
                onClick={() => navigate(index.path)}
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  padding: 2,
                  color: "black",
                  "&:hover": {
                    color: "#00509b",
                  },
                  "&:active": {
                    color: "#00509b",
                  },
                }}
              >
                {index.name}
              </Button>
            ))}
            <Button
              variant="contained"
              sx={{ backgroundColor: "#00509D", marginX: 2 }}
              disableElevation
              onClick={() => navigate(navItems[1].path)}
              px={3}
            >
              Contact Sales
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              color: "black",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default NavBar;
