import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Stack, Typography } from '@mui/material';
import paypal from "../Assets/paypal.png"
import paymob from "../Assets/paymob.png"
import stripe from "../Assets/stripe.png"

const CustomRadioButton = ({ item, setSelectedPaymentOption }) => {
    const logo = item.paymentMethod
    return (
        <FormControlLabel
            value={item.paymentMethod}
            control={<Radio />}
            label={
                <Stack direction={"row"}  spacing={2} justifyContent={"center"} alignItems={"center"}>

                    <img src={item.paymentMethod === "paypal" ? paypal : item.paymentMethod === "paymob" ? paymob : stripe} alt={item.paymentMethod} style={{ width: "100px", height: "100px", cursor: "pointer" , objectFit:"contain"}} onClick={() => setSelectedPaymentOption(item.paymentMethod)} />
                    {/* <Typography component={"span"} sx={{ marginTop: "5px" }}>{item.paymentMethod}</Typography> */}
                </Stack>
            }
        />
    );
};

export default CustomRadioButton;
