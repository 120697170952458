import { Box, Stack, Typography, Icon } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CheckCircleOutlineOutlined, ErrorOutlineOutlined } from '@mui/icons-material'

export default function PaymentStatusPage() {
    const urlParams = new URLSearchParams(window.location.search);
const success = urlParams.get('success');



    return (
        <Box sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f0f0f0", // background color
        }}>
            <Stack sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f0f0f0", // background color
                borderRadius: "8px", // border radius
                padding: "20px", // padding
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // shadow
            }}>
                {success === "true" ?
                    <>
                        <CheckCircleOutlineOutlined color="success" sx={{fontSize:"80px"}} />
                        <Typography sx={{ marginBottom: "10px", marginTop: "20px", fontSize: "18px", fontWeight: "bold" }}>Thank you! We have successfully received your payment.</Typography>
                    </>
                    :
                    <>
                        <ErrorOutlineOutlined color='error'  sx={{fontSize:"80px"}} />
                        <Typography sx={{ marginBottom: "10px", marginTop: "20px", fontSize: "18px", fontWeight: "bold" }}>Payment failed. Please try again later.</Typography>
                    </>
                }
            </Stack>
        </Box>
    )
}
